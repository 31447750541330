import 'scroll-behavior-polyfill';

(function(){

  //ナビゲーションを複製
  const baseNavElement = document.querySelector('.nav-wrap');
  const cloneNavElement = baseNavElement.cloneNode(true);
  cloneNavElement.className = 's-nav-wrap';
  baseNavElement.after(cloneNavElement);

  //子リストを移動
  const children = document.querySelectorAll('.s-nav-wrap .child-column');
  for ( let i = 0; i < children.length; i++ ){
    const toggle = document.querySelector('.s-nav-wrap [data-toggle="' + children[i].id + '"]');
    toggle.parentNode.appendChild(children[i]);
  }

  //ご利用案内を複製
  const footerArea = document.querySelector('.footer-inner .container');
  const baseTimeElement = document.querySelector('.time');
  const cloneTimeElement = baseTimeElement.cloneNode(true);
  cloneTimeElement.className = 's-time';
  footerArea.appendChild( cloneTimeElement );

  //SNSアイコンを複製
  const baseSocialElement = document.querySelector('.social-wrpper');
  const cloneSocialElement = baseSocialElement.cloneNode(true);
  cloneSocialElement.className = 's-social-wrpper';
  footerArea.appendChild( cloneSocialElement );

  //スマホメニューオープン
  const activeClassName = 'active';
  const openTrigger = document.querySelector( '.open' );
  const closeTrigger = document.querySelector( '.s-nav-wrap .close' );
  const target = document.querySelector( '.s-nav-wrap' );
  openTrigger.addEventListener('click', function(){ target.classList.add(activeClassName); });
  closeTrigger.addEventListener('click', function(){ target.classList.remove(activeClassName); });

}());

// slideUp
const slideup = (el, duration = 300) => {
  el.style.height = el.offsetHeight + "px";
  el.offsetHeight;
  el.style.transitionProperty = "height, margin, padding";
  el.style.transitionDuration = duration + "ms";
  el.style.transitionTimingFunction = "ease";
  el.style.overflow = "hidden";
  el.style.height = 0;
  el.style.paddingTop = 0;
  el.style.paddingBottom = 0;
  el.style.marginTop = 0;
  el.style.marginBottom = 0;
  setTimeout(() => {
    el.style.display = "none";
    el.style.removeProperty("height");
    el.style.removeProperty("padding-top");
    el.style.removeProperty("padding-bottom");
    el.style.removeProperty("margin-top");
    el.style.removeProperty("margin-bottom");
    el.style.removeProperty("overflow");
    el.style.removeProperty("transition-duration");
    el.style.removeProperty("transition-property");
    el.style.removeProperty("transition-timing-function");
  }, duration);
};

// slideDown
const slidedown = (el, duration = 300) => {
  el.style.removeProperty("display");
  let display = window.getComputedStyle(el).display;
  if (display === "none") {
    display = "block";
  }
  el.style.display = display;
  let height = el.offsetHeight;
  el.style.overflow = "hidden";
  el.style.height = 0;
  el.style.paddingTop = 0;
  el.style.paddingBottom = 0;
  el.style.marginTop = 0;
  el.style.marginBottom = 0;
  el.offsetHeight;
  el.style.transitionProperty = "height, margin, padding";
  el.style.transitionDuration = duration + "ms";
  el.style.transitionTimingFunction = "ease";
  el.style.height = height + "px";
  el.style.removeProperty("padding-top");
  el.style.removeProperty("padding-bottom");
  el.style.removeProperty("margin-top");
  el.style.removeProperty("margin-bottom");
  setTimeout(() => {
    el.style.removeProperty("height");
    el.style.removeProperty("overflow");
    el.style.removeProperty("transition-duration");
    el.style.removeProperty("transition-property");
    el.style.removeProperty("transition-timing-function");
  }, duration);
};

// slideToggle
const slidetoggle = (el, duration = 300) => {
  if (window.getComputedStyle(el).display === "none") {
    return slidedown(el, duration);
  } else {
    return slideup(el, duration);
  }
};

(function(){

  //アクティブ状態のときのクラス名
  const activeClassName = 'active';

  //子メニューの開閉
  const childTriggerClassName = 'js-child-trigger';
  const childWrapperClassName = 'js-child-wrapper';
  const childToggle = document.getElementsByClassName( childTriggerClassName );
  if ( childToggle !== null && childToggle !== undefined  ) {
    for ( let i = 0; i < childToggle.length; i++ ){
      childToggle[i].addEventListener('click', function( e ){
        e.preventDefault();
        //アクティブ判定
        const isActive = this.classList.contains( activeClassName );
        //クリックした要素の子メニュー
        const panel = this.parentNode.nextElementSibling;
        //クリックした要素の親トグル
        const parent = this.closest( '.' + childWrapperClassName ).querySelectorAll( '.' + childTriggerClassName )[0];
        //全トグルリスト
        const allToggles = document.getElementsByClassName(childTriggerClassName);
        //一度全部閉じる
        for ( let j = 0; j < allToggles.length; j++ ) {
          //子メニュー
          const panel = allToggles[j].parentNode.nextElementSibling;
          //除外条件
          if( allToggles[j] !== childToggle[i] && allToggles[j] !== parent ){
            slideup( panel, 300 );
            allToggles[j].classList.remove( activeClassName );
          }
        };
        //開閉
        if( isActive ){
          this.classList.remove( activeClassName );
          slideup( panel, 300 );
        }else{
          this.classList.add( activeClassName );
          slidedown( panel, 300 );
        }

      });
    };
  };

  //メニューのトリガー
  const navTriggerClassName = 'js-nav-trigger';

  //メニューの開閉（スマホ）
  const snavToggle = document.querySelectorAll( '.s-nav-wrap' + ' .' + navTriggerClassName);
  if ( snavToggle !== null && snavToggle !== undefined  ) {
    for ( let i = 0; i < snavToggle.length; i++ ){
      snavToggle[i].addEventListener('click', function( e ){
        e.preventDefault();
        //アクティブ判定
        const isActive = this.classList.contains( activeClassName );
        //クリックした要素の子メニュー
        const panel = this.nextElementSibling;
        //全トグルリスト
        const allToggles = document.querySelectorAll( '.s-nav-wrap' + ' .' + navTriggerClassName);
        //一度全部閉じる
        for ( let j = 0; j < allToggles.length; j++ ) {
          //子メニュー
          const panel = allToggles[j].nextElementSibling;
          //除外条件
          if( allToggles[j] !== snavToggle[i] ){
            slideup( panel, 300 );
            allToggles[j].classList.remove( activeClassName );
          }
        };
        const allChildToggles = document.getElementsByClassName(childTriggerClassName);
        //一度全部閉じる
        for ( let k = 0; k < allChildToggles.length; k++ ) {
          //子メニュー
          const childPanel = allChildToggles[k].parentNode.nextElementSibling;
          //除外条件
          if( panel.contains(allChildToggles[k]) === false ){
            slideup( childPanel, 300 );
            allChildToggles[k].classList.remove( activeClassName );
          }
        };
        //開閉
        if( isActive ){
          this.classList.remove( activeClassName );
          slideup( panel, 300 );
        }else{
          this.classList.add( activeClassName );
          slidedown( panel, 300 );
        }
      });
    };
  };

  //メニューの開閉（PC）
  const navToggle = document.querySelectorAll( '.nav-wrap' + ' .' + navTriggerClassName);
  if ( navToggle !== null && navToggle !== undefined  ) {
    for ( let i = 0; i < navToggle.length; i++ ){
      navToggle[i].addEventListener('click', function( e ){
        e.preventDefault();
        //アクティブ判定
        const isActive = this.classList.contains( activeClassName );
        //クリックした要素の子メニュー
        const targetId = this.getAttribute( 'data-toggle' );
        const panel = document.getElementById(targetId);
        //全トグルリスト
        const allToggles = document.querySelectorAll( '.nav-wrap' + ' .' + navTriggerClassName);
        //一度全部閉じる
        for ( let j = 0; j < allToggles.length; j++ ) {
          //子メニュー
          const targetId = allToggles[j].getAttribute( 'data-toggle' );
          const panel = document.getElementById(targetId);
          //除外条件
          if( allToggles[j] !== navToggle[i] ){
            slideup( panel, 0 );
            allToggles[j].classList.remove( activeClassName );
          }
        };
        const allChildToggles = document.getElementsByClassName(childTriggerClassName);
        //一度全部閉じる
        for ( let k = 0; k < allChildToggles.length; k++ ) {
          //子メニュー
          const childPanel = allChildToggles[k].parentNode.nextElementSibling;
          //除外条件
          if( panel.contains(allChildToggles[k]) === false ){
            slideup( childPanel, 300 );
            allChildToggles[k].classList.remove( activeClassName );
          }
        };
        //開閉
        if( isActive ){
          this.classList.remove( activeClassName );
          slideup( panel, 300 );
        }else{
          this.classList.add( activeClassName );
          slidedown( panel, 300 );
        }
      });
    };
  };

}());

(function(){

  //現在のページのナビをアクティブに
  const currentPath = location.pathname;
  const navElm = document.querySelector( '.nav-wrap' );
  const navAllLinks = navElm.querySelectorAll( 'a' );
  const activeClassName = 'active';
  if ( currentPath !== '/' && navAllLinks !== null && navAllLinks !== undefined  ) {
    for ( let i = 0; i < navAllLinks.length; i++ ){
      const navLink = new URL(navAllLinks[i].href);
      const navLinkPath = navLink.pathname;
      if(navLinkPath === currentPath){
        const parent = navAllLinks[i].closest('.child-column');
        const parentId = parent.id;
        const parentToggle = document.querySelector( '.nav-wrap [data-toggle="' + parentId + '"]' );
        const listWraps = navElm.querySelectorAll( '.sub-child-item' );
        parentToggle.classList.add( activeClassName );
        slidedown( parent, 0 );
        for ( let j = 0; j < listWraps.length; j++ ){
          if(listWraps[j].contains(navAllLinks[i])){
            const childToggle = listWraps[j].previousElementSibling.querySelector( '.js-child-trigger' );
            childToggle.classList.add( activeClassName );
            slidedown( listWraps[j], 0 );
          }
        }
        return;
      }
    };
  };

}());

(function(){

  const ps = new
  PerfectScrollbar('.scroll-content', {
    suppressScrollX: true
  });

  const resizeObserver = new ResizeObserver((elements) => {
    ps.update();
  });
  resizeObserver.observe(document.querySelector('.header-inner'));
  resizeObserver.observe(document.querySelector('html'));

}());